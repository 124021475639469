"use client"

import { useTheme } from "~/hooks/useTheme"

import { ClientOnly } from "./ClientOnly"

export function ThemeToggle() {
  const { theme, toggleTheme } = useTheme()

  return (
    <button
      aria-label="Toggle Dark/Light Mode"
      onClick={toggleTheme}
      className="group rounded-md bg-gray-100 p-10 transition-all duration-200 hover:bg-gray-200 focus:outline-none focus:ring-2 dark:bg-trueGray-900 dark:hover:bg-trueGray-700"
    >
      <ThemeIcon />
      <div className="pointer-events-none absolute top-[50%] ml-40 hidden h-30 w-40 min-w-max -translate-y-1/2 items-center justify-center rounded-md bg-gray-50 font-inter text-xs font-medium text-trueGray-800 opacity-0 ring-2 ring-gray-200 transition-opacity duration-75 dark:bg-gray-800 dark:text-gray-200 dark:ring-gray-700 sm:flex sm:group-hover:opacity-100">
        <ClientOnly>{theme === "dark" ? "Dark" : "Light"}</ClientOnly>
      </div>
    </button>
  )
}

function ThemeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 20 20" fill="none" {...props}>
      <clipPath id="half-circle-right">
        <circle cx="0" cy="10" r="10" />
      </clipPath>
      <circle
        cx="10"
        cy="10"
        r="10"
        clipPath="url(#half-circle-right)"
        className="fill-current text-black dark:text-white"
      />
      <circle
        cx="10"
        cy="10"
        r="10"
        className="fill-current text-black opacity-10 dark:text-white-100 dark:opacity-10"
      />
    </svg>
  )
}
