import { getCookie, setCookie } from "cookies-next"
import { OptionsType } from "cookies-next/lib/types"

export type Theme = "light" | "dark"

const ThemeCookie = "osamaqarem_com_theme"

export const themeStore = {
  get: (opt?: OptionsType) => getCookie(ThemeCookie, opt) as Theme | undefined,
  set: (theme: Theme, opt?: OptionsType) => setCookie(ThemeCookie, theme, opt),
}
