"use client"

import * as React from "react"

import { Theme, themeStore } from "~/lib/theme-store"

declare const window: {
  __systemTheme: Theme
}

export interface ThemeState {
  theme: Theme
  toggleTheme: () => void
}

export const ThemeContext = React.createContext<ThemeState | undefined>(
  undefined,
)

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [theme, _setTheme] = React.useState<Theme>(themeStore.get() ?? "light")

  React.useEffect(() => {
    _setTheme(window.__systemTheme)
    document.documentElement.classList.remove("disable-transition")
  }, [])

  const setTheme = (theme: Theme) => {
    themeStore.set(theme)
    _setTheme(theme)
  }

  const toggleTheme = () => {
    const getThemeElement = () => document.documentElement

    getThemeElement()?.classList.add("disable-transition")

    switch (theme) {
      case "light":
        getThemeElement()?.classList.remove("light")
        getThemeElement()?.classList.add("dark")
        setTheme("dark")
        break
      case "dark":
        getThemeElement()?.classList.add("light")
        getThemeElement()?.classList.remove("dark")
        setTheme("light")
        break
    }

    setTimeout(() => {
      getThemeElement()?.classList.remove("disable-transition")
    }, 400)
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
