import { useState, useEffect } from "react"

export function ClientOnly({ children }: React.PropsWithChildren) {
  const [clientHydrated, setClientHydrated] = useState(false)

  useEffect(() => {
    setClientHydrated(true)
  }, [])

  if (!clientHydrated) {
    return null
  }

  return <>{children}</>
}
