import { use } from "react"

import { ThemeContext, ThemeState } from "~/components/ThemeProvider"

export const useTheme = (): ThemeState => {
  const theme = use(ThemeContext)

  if (theme === undefined) {
    throw Error("useTheme must be used within ThemeProvider")
  }

  return theme
}
